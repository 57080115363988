import { FC } from "react";
import { 
    FacebookShareButton, 
    TwitterShareButton, 
    WhatsappShareButton,
    TelegramShareButton,
    EmailShareButton,
    LinkedinShareButton,
    LineShareButton,
    ViberShareButton,
    PinterestShareButton,
    TumblrShareButton,
    InstapaperShareButton,
    MailruShareButton,
    WorkplaceShareButton,
    WeiboShareButton,
    LivejournalShareButton
} from "react-share";
import { 
    FacebookIcon, 
    WhatsappIcon,
    TelegramIcon,
    EmailIcon,
    XIcon,
    LinkedinIcon,
    LineIcon,
    ViberIcon,
    PinterestIcon,
    TumblrIcon,
    InstapaperIcon, 
    MailruIcon,
    WorkplaceIcon,
    WeiboIcon,
    LivejournalIcon
} from "react-share";
import { KTSVG } from "../../../_metronic/helpers";
import Swal from "sweetalert2";

type Props = {
    url: string,
    facebook?: boolean,
    x?: boolean,
    whatsapp?: boolean,
    telegram?: boolean,
    linkedin?: boolean,
    line? : boolean,
    viber?: boolean,
    pinterest?: boolean,
    tumblr?: boolean,
    instapaper?: boolean,
    mailru?: boolean,
    workplace?: boolean,
    weibo?: boolean,
    livejournal?: boolean
}

const SuperboltSocial: FC<Props> = ({ 
    url, 
    facebook = true, 
    x = true, 
    whatsapp = true, 
    telegram = true,
    linkedin = true,
    pinterest = true,
    tumblr = true,
    instapaper = true,
    line = true,
    viber = false,
    mailru = true,
    workplace = true,
    weibo = true,
    livejournal  =true
}) => {

    const copyToClipboard = async (text: string) => {
        try {
          await navigator.clipboard.writeText(text);
          const Toast = Swal.mixin({
            toast: true,
            position: 'bottom-right',
            iconColor: 'green',
            customClass: {
              popup: 'colored-toast'
            },
            showConfirmButton: false,
            timer: 2000,
            // timerProgressBar: true
          })
          await Toast.fire({
            icon: 'success',
            title: `Share campaign link copied to clipboard.`,
          })
        } catch (error) {
    
        }
      };

    return (
        <div className="d-flex flex-column content-justify-center flex-row-fluid mt-5">
            <div className="text-center d-flex" style={{flexDirection:'row', flexWrap:'wrap'}} role="group">
                <div className="me-3 ms-3 mb-3">
                        <EmailShareButton
                            url={url}
                        >
                            <EmailIcon size={48} round />
                        </EmailShareButton>
                    </div>
                {facebook &&
                    <div className="me-3 ms-3 mb-3">
                        <FacebookShareButton
                            url={url}
                            hashtag={"#Superbolt"}
                            
                        >
                            <FacebookIcon size={48} round />
                        </FacebookShareButton>
                    </div>
                }
                {x &&
                    <div className="me-3 ms-3 mb-5">
                        <TwitterShareButton
                            url={url}
                        >
                            <XIcon size={48} round />
                        </TwitterShareButton>
                    </div>
                }
                {linkedin &&
                    <div className="me-3 ms-3 mb-5">
                        <LinkedinShareButton
                            url={url}
                        >
                            <LinkedinIcon size={48} round />
                        </LinkedinShareButton>
                    </div>
                }
                {whatsapp &&
                    <div className="me-3 ms-3 mb-5">
                        <WhatsappShareButton
                            url={url}
                        >
                            <WhatsappIcon size={48} round />
                        </WhatsappShareButton>
                    </div>
                }
                {telegram &&
                    <div className="me-3 ms-3 mb-5">
                        <TelegramShareButton
                            url={url}
                        >
                            <TelegramIcon size={48} round />
                        </TelegramShareButton>
                    </div>
                }
                {line &&
                    <div className="me-3 ms-3 mb-5">
                        <LineShareButton
                            url={url}
                        >
                            <LineIcon size={48} round />
                        </LineShareButton>
                    </div>
                }
                {viber &&
                    <div className="me-3 ms-3 mb-5">
                        <ViberShareButton
                            url={url}
                        >
                            <ViberIcon size={48} round />
                        </ViberShareButton>
                    </div>
                }
                {pinterest &&
                    <div className="me-3 ms-3 mb-5">
                        <PinterestShareButton
                            url={url}
                            media={url}
                        >
                            <PinterestIcon size={48} round />
                        </PinterestShareButton>
                    </div>
                }
                {tumblr &&
                    <div className="me-3 ms-3 mb-5">
                        <TumblrShareButton
                            url={url}
                        >
                            <TumblrIcon size={48} round />
                        </TumblrShareButton>
                    </div>
                }
                {instapaper &&
                    <div className="me-3 ms-3 mb-5">
                        <InstapaperShareButton
                            url={url}
                        >
                            <InstapaperIcon size={48} round />
                        </InstapaperShareButton>
                    </div>
                }
                {mailru &&
                    <div className="me-3 ms-3 mb-5">
                        <MailruShareButton
                            url={url}
                        >
                            <MailruIcon size={48} round />
                        </MailruShareButton>
                    </div>
                }
                {workplace &&
                    <div className="me-3 ms-3 mb-5">
                        <WorkplaceShareButton
                            url={url}
                        >
                            <WorkplaceIcon size={48} round />
                        </WorkplaceShareButton>
                    </div>
                }
                {weibo &&
                    <div className="me-3 ms-3 mb-5">
                        <WeiboShareButton
                            url={url}
                        >
                            <WeiboIcon size={48} round />
                        </WeiboShareButton>
                    </div>
                }
                {livejournal &&
                    <div className="me-3 ms-3 mb-5">
                        <LivejournalShareButton
                            url={url}
                        >
                            <LivejournalIcon size={48} round />
                        </LivejournalShareButton>
                    </div>
                }
            </div>
            <div className="pt-5">
                <div className="border border-info rounded d-flex justify-content-between p-2 bg-light-info text-info">
                    <span className="text-start me-2">{url}</span>
                    <span className="text-end">
                        <a
                          className='btn btn-sm btn-light-info p-0 pb-1'
                          title='Copy link'
                          onClick={() => copyToClipboard(`${url}`)}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen054.svg'
                            className='svg-icon svg-icon-4 p-1 ms-1 svg-icon-info '
                          />
                        </a>
                    </span>
                </div>
                
            </div>
        </div>

    )
}

export { SuperboltSocial }